import React, { Component } from 'react';

class Action extends Component {
    render() {
        return (
        <section class="cid-rSRoX8rw95 mbr-midiscreen mbr-parallax-background item" style={{backgroundImage: 'url("/images/gallery/ptok/pt02.jpg")'}}>
            <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
            <div class="container align-center">
               <div class="row justify-content-md-center">
                  <div class="mbr-white col-md-10">
                     <img class="logo-banner" src="/images/logo-2.png" width={'70%'} alt="" />
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Action