import React, { Component } from 'react';



class Slider extends Component {
    render() {
        return (
            <header class="slider-carousel owl-carousel owl-theme">
               
                   {/* Slider 0 */}
            <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/pt12.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 0 */}

                {/* Slider 1 */}
            <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/ca03.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
             
            </section>
            {/* end Slider 1 */}

                   {/* Slider 2 */}
                <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/fa05.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 2 */}

            {/* Slider 3 */}
            <section class="cid-rSRoX8rw95 mbr-fullscreen item" id="header2-6" style={{backgroundImage: 'url(/images/slider/ig02.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 3 */}

            {/* Slider 4 */}
            <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/pt04.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 4 */}

             {/* Slider 5 */}
             <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/vi02.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 5 */}

                 {/* Slider 6 */}
                 <section class="cid-rSRoX8rw95 mbr-fullscreen  item" id="header2-6" style={{backgroundImage: 'url(/images/slider/vi12.jpg)'}}>
               <div class="mbr-overlay" style={{opacity: '0.5' , backgroundColor: 'rgb(35, 35, 35)'}}></div>
               <div class="container align-center">
                  <div class="row justify-content-md-center">
                     <div class="mbr-white col-md-10">
                        <img class="logo-banner" src="/images/logo-2.png" width="70%" alt="" />
                        <h2 class=" mbr-bold pb-3 mbr-fonts-style ">
                           Bem-vindo
                        </h2>
                        <p class="mbr-text pb-3 mbr-fonts-style display-5">Obrigado por sua visita a página da Vera Cruz Tours! Somos especialistas em inteligência turística, proporcionando as melhores soluções e experiências em Portugal!</p>
                        <div class="mbr-section-btn">
                           <a class="btn btn-md btn-secondary display-4" href="#header16-a">Saiba mais</a>
                        </div>
                     </div>
                  </div>
               </div>
              
            </section>
            {/* end Slider 6 */}
       
           
         </header>
          );
    }
 
}

export default Slider;