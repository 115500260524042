import React, { Component } from 'react';



class Portugal extends Component {
    render() {
        return (

            <section class="counters4 counters cid-rSRrRLlBky" id="counters4-c">
            <div class="container pt-4 mt-2">
               <div class="section-heading">
                  <div class="text-center"> 
                     <img class="text-center" src="/images/portugal.svg"  alt="" style={{ width: '64px' }} />
                  </div>
                  <h2 class="mbr-section-title title pb-3 align-center mbr-fonts-style display-2">TURISMO EM PORTUGAL - AVANTE !</h2>
                  <h3 class="mbr-section-subtitle pb-5 align-center mbr-fonts-style display-5">(Fonte Instituto Nacional de Estatística de Portugal - dados 2018)</h3>
                  <span class="border-divisor"></span>
               </div>
               <div class="media-container-row">
                  <div class="media-block m-auto" >
                     <div class="mbr-figure">
                        <img src="/images/turismo.jpg" class="img-responsive" style={{ width: '50vh' }} />
                     </div>
                  </div>
                  <div class="cards-block">
                     <div class="cards-container">
                        <div class="card px-3 align-left col-12">
                           <div class="panel-item p-4 d-flex align-items-start">
                              <div class="card-img pr-3">
                                 <h3 class="img-text d-flex align-items-center justify-content-center">
                                    1
                                 </h3>
                              </div>
                              <div class="card-text">
                                 <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">PIB</h4>
                                 <p class="mbr-content-text mbr-fonts-style display-7">
                                    PIB de 19,1% em 2018 = 41.500 milhões de euros
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div class="card px-3 align-left col-12">
                           <div class="panel-item p-4 d-flex align-items-start">
                              <div class="card-img pr-3">
                                 <h3 class="img-text d-flex align-items-center justify-content-center">
                                    2
                                 </h3>
                              </div>
                              <div class="card-text">
                                 <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">TURISTAS ESTRANGEIROS</h4>
                                 <p class="mbr-content-text mbr-fonts-style display-7">
                                    22,8 milhões de turistas estrangeiros 
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div class="card px-3 align-left col-12">
                           <div class="panel-item p-4 d-flex align-items-start">
                              <div class="card-img pr-3">
                                 <h3 class="img-text d-flex align-items-center justify-content-center">
                                    3
                                 </h3>
                              </div>
                              <div class="card-text">
                                 <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">COMPARATIVO</h4>
                                 <p class="mbr-content-text mbr-fonts-style display-7">
                                    Aumento de 7,5% em comparação ao ano anterior
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div class="card px-3 align-left col-12">
                           <div class="panel-item p-4 d-flex align-items-start">
                              <div class="card-img pr-3">
                                 <h3 class="img-text d-flex align-items-center justify-content-center">
                                    4
                                 </h3>
                              </div>
                              <div class="card-text">
                                 <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">TURISTAS BRASILEIROS</h4>
                                 <p class="mbr-content-text mbr-fonts-style display-7">
                                    1,2 milhão de turistas Brasileiros
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div class="card px-3 align-left col-12">
                           <div class="panel-item p-4 d-flex align-items-start">
                              <div class="card-img pr-3">
                                 <h3 class="img-text d-flex align-items-center justify-content-center">
                                    5
                                 </h3>
                              </div>
                              <div class="card-text">
                                 <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">RANKING MUNDIAL</h4>
                                 <p class="mbr-content-text mbr-fonts-style display-7">
                                    Ranking mundial de turistas em Portugal: 1º Espanha, 2º Reino Unido, 3º França, 4º Alemanha e 5º Brasil
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

      
 

          );
    }
 
}

export default Portugal;