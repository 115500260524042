import React, { Component } from 'react';



class Services extends Component {
    render() {

        const serviceIcon = {
           width: '40px'
          };

        return (
        <section >
            
            <div class="mbr-section content4 cid-rSRs7ooxGt" id="content4-e">
            <div class="container">
            <div class="media-container-row">
               <div class="title col-12 col-md-8">
                  <h2 class="align-center pb-3 mbr-fonts-style display-2">
                     Nossos Serviços
                  </h2>
                  <h3 class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                     Confira todos os serviços que oferecemos
                  </h3>
               </div>
            </div>
         </div>
            </div>

           

         <div class="features1 cid-rSRs3ghQpe" id="features1-d">
         <div class="container">
            <div class="media-container-row">
               <div class="card p-3 col-12 col-md-6 col-lg-3">
                  <div class="card-img pb-3">
                     <img src="/images/traslado.svg" width={120}   alt="" />
                   
                  </div>
                  <div class="card-box">
                     <h4 class="card-title py-3 mbr-fonts-style display-5">
                        Traslados
                     </h4>
                   
                  </div>
               </div>
               <div class="card p-3 col-12 col-md-6 col-lg-3">
                  <div class="card-img pb-3">
                     <img src="/images/familia.svg"  width={120} alt="" />
                  </div>
                  <div class="card-box">
                     <h4 class="card-title py-3 mbr-fonts-style display-5">
                        Pacotes personalizados 
                     </h4>
                  
                  </div>
               </div>
               <div class="card p-3 col-12 col-md-6 col-lg-3">
                  <div class="card-img pb-3">
                     <img src="/images/adaptados.svg"  width={120}  alt="" />
                    
                  </div>
                  <div class="card-box">
                     <h4 class="card-title py-3 mbr-fonts-style display-5">
                        Pacotes adaptados 
                     </h4>
                    
                  </div>
               </div>
               <div class="card p-3 col-12 col-md-6 col-lg-3">
                  <div class="card-img pb-3">
                     <img src="/images/tours.svg"  width={120} alt=""/>
                    
                  </div>
                  <div class="card-box">
                     <h4 class="card-title py-3 mbr-fonts-style display-5">
                        Tours
                     </h4>
                    
                  </div>
               </div>
            </div>
         </div>
      </div>  

      </section>

      
     
      
 

          );
    }
 
}

export default Services;