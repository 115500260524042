import React, { Component } from 'react';



class About extends Component {
    render() {
        return (

      <section class="header1 cid-rSRq7Hfv8M about-section" id="header16-a">
         <div class="container">
            <div class="row justify-content-md-center">
               <div class="col-md-10 align-center">
                  <h1 class="mbr-section-title title mbr-bold pb-3 mbr-fonts-style display-1">INTELIGÊNCIA TURÍSTICA</h1>
                  <p class="mbr-text pb-3 mbr-fonts-style display-5">
                     Somos especialistas em pesquisas turísticas. Temos um trabalho de inteligência buscando sempre 
                     novas alternativas para um dos destinos mais atraentes do mundo: Portugal! Um país de natureza 
                     exuberante e com um povo que sabiamente preservou a história e a cultura. 
                     <br />
                     <br />
                     Com base nesse cenário 
                     buscamos novas experiências em turismo, agradando aos mais diferentes públicos.

Diga-nos a sua necessidade e apresentaremos as melhores soluções. Seja para uma viagem em família, individual ou em grupos</p>


                  <div class="mbr-section-btn"><a class="btn btn-md btn-black-outline display-4" href="#counters4-c">Veja Mais</a></div>
               </div>
            </div>
         </div>
      </section>

      
 

          );
    }
 
}

export default About;