import React, { Component } from 'react';

class Partner extends Component {
    render() {
        return (
            <section class="clients cid-rSRt0zJYou" data-interval="false" id="clients-g">
            <div class="container mb-5">
               <div class="media-container-row">
                  <div class="col-12 align-center">
                     <h2 class="mbr-section-title pb-3 mbr-fonts-style display-2">Nossos Parceiros</h2>
                     <h3 class="mbr-section-subtitle mbr-light mbr-fonts-style display-5">
                        Trabalhamos diretamente com essas grandes empresas
                     </h3>
                  </div>
               </div>
            </div>
            <div class="container">
              
                  <div class="partner-carousel owl-carousel owl-theme">
                     <div class="item ">
                        <img src="/images/1.png" width={40} style={{ width: '150px' }} />
                     </div>
                     <div class="item ">
                        <img src="/images/2.png" width={40} style={{ width: '150px' }} />
                     </div>
                     <div class="item ">
                        <img src="/images/3.png" width={40} style={{ width: '150px' }} />
                     </div>
                     <div class="item ">
                        <img src="/images/4.png" width={40} style={{ width: '150px' }} />
                     </div>
                     <div class="item ">
                        <img src="/images/5.png" width={40} style={{ width: '150px' }} />
                     </div>
                  
                 
                    
                  </div>
             
             
            </div>
         </section>
        );
    }
}

export default Partner