import React from 'react';
import logo from './logo.svg';



import Header from  './components/HeaderComponents/Header';
import Slider from  './components/SliderComponents/Slider';
import About from   './components/AboutComponents/About';
import Portugal from   './components/PortugalComponents/Portugal';
import Services from   './components/ServicesComponents/Services';
import Tours from   './components/ToursComponents/Tours';
import Gallery from   './components/GalleryComponents/Gallery';
import Action from   './components/ActionComponents/Action';
import Partner from  './components/PartnerComponents/Partner';
import Contatos from  './components/ContatosComponents/Contatos';
import Footer from  './components/FooterComponents/Footer';

// Import Pages
// import Empresa from './Empresa';
// import Home from './Home';

import {BrowserRouter as Router, Switch, Route, Link} from  'react-router-dom'; 

import './App.css';


function App() {
  return (

    <div className="app">
      <Slider />
      <Header />
      <About />
      <Portugal />
      <Services />
      <Tours />
      <Gallery />
      <Action />
      {/* <Partner/> */}
      <Contatos/>
      <Footer />
    </div>
 
    //   <Router>         
    //        <div className="App">
    //           <Switch>
    //               <Route path="/" exact component={Home} />
    //               <Route path="/empresa" component={Empresa} />
    //           </Switch>
    //           <Header/>
    //           <Footer /> 
    //       </div>
    //  </Router>


    

  );
}

// const Home = () => (
//   <div>
  
//   </div>
  
// );

// const Empresa = () => (
//   <div>
//     <Empresa />
//   </div>
// );

export default App;
