import React, { Component } from 'react';



class Tours extends Component {
    render() {
        return (
       

            <section class="services3 cid-rSRsxgDoNX" id="services3-f">
          
            <div class="container">
               <div class="">

               <div class="media-container-row">
                  <div class="col-12 align-center section-heading">
                     <h2 class="mbr-section-title title pb-3 mbr-fonts-style display-2"> Detalhamentos Tours</h2>
                     <h3 class="mbr-section-subtitle mbr-light mbr-fonts-style display-5">Conheça todos os detalhes de nossos pacotes.</h3>
                     </div>
               </div>
             

                  <div class="row">
                     <div class="col-md-4  px-3 col-12 ">
                        <div class="card card-inverse card-info">
                           <figure class="card-img-top" style={{backgroundImage: 'url(/images/gallery/igok/ig01.jpg)'}}></figure>
                              
                              <div class="card-block">
                                 
                              <h4 class="card-title mbr-fonts-style display-5"><i class="fas fa-church"></i> RELIGIOSOS</h4>
                                    <div class="card-text">
                                    A história de Portugal contada através da arte e arquitetura de igrejas milenares
                                    </div>
                              </div>
                              <div class="card-footer">
                                    
                                    <button class="btn btn-primary float-right btn-sm" data-toggle="modal" data-target="#religiosoModal">Leia mais</button>
                              </div>
                        </div>
                     </div>

                     <div class="col-md-4  px-3 col-12">
                        <div class="card card-inverse card-info">
                           <figure class="card-img-top" style={{backgroundImage: 'url(/images/gallery/viok/vi02.jpg)'}}></figure>
                              
                              <div class="card-block">
                                 
                              <h4 class="card-title mbr-fonts-style display-5"><i class="fas fa-wine-glass-alt"></i>  Wine Tours </h4>
                                    <div class="card-text">
                                       Uma jornada regada a vinhos e histórias inesquecíveis.
                                    </div>
                              </div>
                              <div class="card-footer">
                                    
                                    <button class="btn btn-primary float-right btn-sm" data-toggle="modal" data-target="#wineModal">Leia mais</button>
                              </div>
                        </div>
                     </div>

                     <div class="col-md-4  px-3 col-12">
                        <div class="card card-inverse card-info">
                           <figure class="card-img-top" style={{backgroundImage: 'url(/images/gallery/caok/ca03.jpg)'}}></figure>
                              
                              <div class="card-block">
                                 
                              <h4 class="card-title mbr-fonts-style display-5"><i class="fas fa-chess-rook"></i>  Portugal Medieval</h4>
                                    <div class="card-text">
                                    Cenários deslumbrantes de uma cultura milenar
                                    </div>
                              </div>
                              <div class="card-footer">
                                   
                                    <button class="btn btn-primary float-right btn-sm" data-toggle="modal" data-target="#medievalModal">Leia mais</button>
                              </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-12">
                     <div class="row justify-content-center">
                     <div class="col-md-4  px-3 col-12">
                           <div class="card card-inverse card-info">
                              <figure class="card-img-top" style={{backgroundImage: 'url(/images/gallery/faok/fa11.jpg)'}}></figure>
                                 
                                 <div class="card-block">
                                    
                                 <h4 class="card-title  display-5"><i class="fas fa-ship"></i>  Buscando a minha origem</h4>
                                       <div class="card-text">
                                       Quer conhecer a região de origem da sua família? 
                                       </div>
                                 </div>
                                 <div class="card-footer">
                                  
                                       <button class="btn btn-primary float-right btn-sm" data-toggle="modal" data-target="#origemModal">Leia mais</button>
                                 </div>
                           </div>
                     </div> 

                     <div class="col-md-4  px-3 col-12">
                           <div class="card card-inverse card-info">
                              <figure class="card-img-top" style={{backgroundImage: 'url(/images/gallery/ptok/pt11.jpg)'}}></figure>
                                 
                                 <div class="card-block">
                                    
                                
                                 <h4 class="card-title mbr-fonts-style display-5"><i class="fas fa-map-marked-alt"></i>  Fast & Personal Tours</h4>
                                       <div class="card-text">
                                          Elaboramos tours totalmente customizáveis, part time ou full time
                                       </div>
                                 </div>
                                 <div class="card-footer">
                                       <button class="btn btn-primary float-right btn-sm" data-toggle="modal" data-target="#personalModal">Leia mais</button>
                                 </div>
                           </div>
                     </div> 
                     </div>
                    
                  </div>


                  {/* <div class="card px-3 col-12 col-md-6">
                     <div class="card-wrapper text-left media-container-row media-container-row">
                        <div class="card-box">
                           <div class="top-line pb-1">
                              <h4 class="card-title mbr-fonts-style display-5">
                                 <i class="fas fa-church"></i> RELIGIOSOS
                              </h4>
                           </div>
                           <div class="bottom-line">
                              <p class="mbr-text mbr-fonts-style display-7">
                                 A história de Portugal contada através da arte e arquitetura de igrejas milenares. Visitas guiadas que proporcionam momentos únicos de fé e contato com edificações de cunho histórico inestimável.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card px-3 col-12 col-md-6">
                     <div class="card-wrapper text-left  media-container-row media-container-row">
                        <div class="card-box">
                           <div class="top-line pb-3">
                              <h4 class="card-title mbr-fonts-style display-5">
                                 <i class="fas fa-wine-glass-alt"></i>  Wine Tours e Rota Gastronômica:
                              </h4>
                           </div>
                           <div class="bottom-line">
                              <p class="mbr-text mbr-fonts-style display-7">
                                 Consagrado entre os especialistas, os vinhos Alentejanos e da Península de Setúbal são uma prazerosa surpresa, e estão próximos a região de Lisboa. Prefere os vinhos do Douro? Sem problemas! Consulte-nos e teremos o maior prazer em acompanhá-lo, em uma jornada regada a vinhos e histórias inesquecíveis.
                                 Desfrute dos prazeres que o vinho proporciona e da comodidade que a Vera Cruz oferece.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                 

                
                  <div class="card px-3 col-12 col-md-6">
                     <div class="card-wrapper text-left  media-container-row media-container-row">
                        <div class="card-box">
                           <div class="top-line pb-3">
                              <h4 class="card-title mbr-fonts-style display-5">
                                 <i class="fas fa-chess-rook"></i>  Portugal Medieval
                              </h4>
                           </div>
                           <div class="bottom-line">
                              <p class="mbr-text mbr-fonts-style display-7">
                                 Das quase 500 edificações de defesa existentes em Portugal, mais da metade são castelos! Alia-se a esse cenário deslumbrante, uma cultura milenar que preserva a história lusitana em belíssimos cenários.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                
                  <div class="card px-3 col-12 col-md-6">
                     <div class="card-wrapper text-left  media-container-row media-container-row">
                        <div class="card-box">
                           <div class="top-line pb-3">
                              <h4 class="card-title mbr-fonts-style display-5">
                                 <i class="fas fa-ship"></i>  Buscando a minha origem
                              </h4>
                           </div>
                           <div class="bottom-line">
                              <p class="mbr-text mbr-fonts-style display-7">
                                 Tem descendência portuguesa? Quer conhecer a região de origem da sua família? Conte a sua história e deixe o restante conosco! Pesquisaremos e levaremos você para qualquer região do país!
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               
                  <div class="card px-3 col-12 col-md-6">
                     <div class="card-wrapper text-left  media-container-row media-container-row">
                        <div class="card-box">
                           <div class="top-line pb-3">
                              <h4 class="card-title mbr-fonts-style display-5">
                                 <i class="fas fa-map-marked-alt"></i>  Fast Tours/ Personal Tours
                              </h4>
                           </div>
                           <div class="bottom-line">
                              <p class="mbr-text mbr-fonts-style display-7">
                                 Necessita de algo mais específico? Sem problemas! Elaboramos tours totalmente customizáveis, part time ou full time, sempre de acordo com a sua preferência.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div> */}
              
               </div>
            </div>

         {/* MODALS TOURS */}
         <div class="modal fade modal-tours" id="religiosoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header " style={{backgroundImage: 'url(/images/gallery/igok/ig01.jpg)'}}>
                  <div class="modal-title " id="exampleModalLabel">
                     <h4 class="tour-title "><i class="fas fa-church"></i> | Religiosos</h4>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body">
                     <div class="text-center">
                        <p>
                           A história de Portugal contada através da arte e arquitetura de igrejas milenares. Visitas guiadas que proporcionam momentos únicos de fé e contato com edificações de cunho histórico inestimável.
                           </p>
                     </div>
                  </div>
                  <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>

         <div class="modal fade modal-tours" id="wineModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header " style={{backgroundImage: 'url(/images/gallery/viok/vi02.jpg)'}}>
                  <div class="modal-title " id="exampleModalLabel">
                  <h4 class="tour-title"><i class="fas fa-wine-glass-alt"></i>  Wine Tours </h4>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body">
                     <div class="text-center">
                        <p>
                        Consagrado entre os especialistas, os vinhos Alentejanos e da Península de Setúbal são uma prazerosa surpresa, e estão próximos a região de Lisboa. Prefere os vinhos do Douro? Sem problemas! Consulte-nos e teremos o maior prazer em acompanhá-lo, em uma jornada regada a vinhos e histórias inesquecíveis. Desfrute dos prazeres que o vinho proporciona e da comodidade que a Vera Cruz oferece.
                        </p>
                     </div>
                  </div>
                  <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>

         <div class="modal fade modal-tours" id="medievalModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header " style={{backgroundImage: 'url(/images/gallery/caok/ca03.jpg)'}}>
                  <div class="modal-title " id="exampleModalLabel">
                  <h4 class="tour-title"><i class="fas fa-chess-rook"></i>  Portugal Medieval</h4>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body">
                     <div class="text-center">
                        <p>
                        Das quase 500 edificações de defesa existentes em Portugal, mais da metade são castelos! Alia-se a esse cenário deslumbrante, uma cultura milenar que preserva a história lusitana em belíssimos cenários.
                        </p>
                     </div>
                  </div>
                  <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>

         <div class="modal fade modal-tours" id="origemModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header " style={{backgroundImage: 'url(/images/gallery/faok/fa11.jpg)'}}>
                  <div class="modal-title " id="exampleModalLabel">
                  <h4 class="tour-title"><i class="fas fa-ship"></i>  Buscando a minha origem</h4>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body">
                     <div class="text-center">
                        <p>
                        Tem descendência portuguesa? Quer conhecer a região de origem da sua família? Conte a sua história e deixe o restante conosco! Pesquisaremos e levaremos você para qualquer região do país!
                        </p>
                     </div>
                  </div>
                  <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>

         <div class="modal fade modal-tours" id="personalModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header " style={{backgroundImage: 'url(/images/gallery/ptok/pt11.jpg)'}}>
                  <div class="modal-title " id="exampleModalLabel">
                  <h4 class="tour-title"><i class="fas fa-map-marked-alt"></i>  Fast &amp; Personal Tours</h4>
                  </div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body">
                     <div class="text-center">
                        <p>
                        Necessita de algo mais específico? Sem problemas! Elaboramos tours totalmente customizáveis, part time ou full time, sempre de acordo com a sua preferência.
                        </p>
                     </div>
                  </div>
                  <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>


         </section>



          );
    }
 
}

export default Tours;