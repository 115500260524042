import React, { Component } from 'react';

class Gallery extends Component {
    render() {
        return (
            <section id="galeria" class="gallery-section">
         <div class="container">
            <div class="media-container-row">
               <div class="col-12 align-center">
                  <h2 class="mbr-section-title title pb-3 mbr-fonts-style display-2">Galeria de Imagens</h2>
                  <h3 class="mbr-section-subtitle mbr-light mbr-fonts-style display-5">
                     Confira nosso catálogo de imagens de pontos turísticos
                  </h3>
               </div>
            </div>
            <div  class="row grid">
             
                <div class="col-md-4 ">
                    <div class="content lightgallery" style={{backgroundImage: 'url("/images/gallery/caok/ca01.jpg")'}}>
                        <a href="/images/gallery/caok/ca01.jpg" >
                            <div class="content-overlay"></div>
                          <img class="content-image" src="/images/gallery/caok/ca01.jpg" style={{ display: 'none' }} />
                          <div class="content-details fadeIn-bottom">
                            <h3 class="content-title">Nome do album</h3>
                            <p class="content-text">Pequena descrição</p>
                          </div>
                        </a>
                          
                          <a href="/images/gallery/caok/ca02.jpg">
                            <img src="/images/gallery/caok/ca02.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca03.jpg">
                            <img src="/images/gallery/caok/ca03.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca04.jpg">
                            <img src="/images/gallery/caok/ca04.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca05.jpg">
                            <img src="/images/gallery/caok/ca05.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca06.jpg">
                            <img src="/images/gallery/caok/ca06.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca07.jpg">
                            <img src="/images/gallery/caok/ca07.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca08.jpg">
                            <img src="/images/gallery/caok/ca08.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca11.jpg">
                            <img src="/images/gallery/caok/ca11.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca12.jpg">
                            <img src="/images/gallery/caok/ca12.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                            <a href="/images/gallery/caok/ca13.jpg">
                            <img src="/images/gallery/caok/ca13.jpg" style={{ display: 'none' }} alt="" />
                            </a>       
                      </div>
                   </div>
           
               <div class="col-md-4 ">
                <div class="content lightgallery" style={{backgroundImage: 'url("/images/gallery/faok/fa01.jpg")'}}>
                    <a href="/images/gallery/faok/fa01.jpg" >
                        <div class="content-overlay"></div>
                      <img class="content-image" src="/images/gallery/faok/fa01.jpg" style={{ display: 'none' }} />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">Nome do album</h3>
                        <p class="content-text">Pequena descrição</p>
                      </div>
                    </a>
                    
                      <a href="/images/gallery/faok/fa02.jpg">
                        <img src="/images/gallery/faok/fa02.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/faok/fa03.jpg">
                        <img src="/images/gallery/faok/fa03.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/faok/fa04.jpg">
                        <img src="/images/gallery/faok/fa04.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/faok/fa05.jpg">
                        <img src="/images/gallery/faok/fa05.jpg" style={{ display: 'none' }} alt="" />
                        </a>

                        <a href="/images/gallery/faok/fa11.jpg">
                        <img src="/images/gallery/faok/fa11.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/faok/fa12.jpg">
                        <img src="/images/gallery/faok/fa12.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/faok/fa13.jpg">
                        <img src="/images/gallery/faok/fa13.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                    
                      
                  </div>
               </div>
              
               <div class="col-md-4 ">
                <div class="content lightgallery" style={{ backgroundImage: 'url("/images/gallery/igok/ig01.jpg")' }}>
                    <a href="/images/gallery/igok/ig01.jpg" >
                        <div class="content-overlay"></div>
                      <img class="content-image" src="/images/gallery/igok/ig01.jpg" style={{ display: 'none' }} />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">Nome do album</h3>
                        <p class="content-text">Pequena descrição</p>
                      </div>
                    </a>
                    
                      <a href="/images/gallery/igok/ig02.jpg">
                        <img src="/images/gallery/igok/ig02.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/igok/ig03.jpg">
                        <img src="/images/gallery/igok/ig03.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/igok/ig04.jpg">
                        <img src="/images/gallery/igok/ig04.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/igok/ig05.jpg">
                        <img src="/images/gallery/igok/ig05.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/igok/ig06.jpg">
                            <img src="/images/gallery/igok/ig06.jpg" style={{ display: 'none' }} alt="" />
                            </a>
                    
                      
                  </div>
               </div>
             

            
               <div class="col-md-4 ">
                <div class="content lightgallery" style={{ backgroundImage: 'url("/images/gallery/ptok/pt01.jpg")' }}>
                    <a href="/images/gallery/ptok/pt01.jpg" >
                        <div class="content-overlay"></div>
                      <img class="content-image" src="/images/gallery/ptok/pt01.jpg" style={{ display: 'none' }} />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">Nome do album</h3>
                        <p class="content-text">Pequena descrição</p>
                      </div>
                    </a>
                 
                      <a href="/images/gallery/ptok/pt02.jpg">
                        <img src="/images/gallery/ptok/pt02.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/ptok/pt03.jpg">
                        <img src="/images/gallery/ptok/pt03.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/ptok/pt04.jpg">
                        <img src="/images/gallery/ptok/pt04.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/ptok/pt05.jpg">
                        <img src="/images/gallery/ptok/pt05.jpg" style={{ display: 'none' }} alt="" />
                        </a>

                        <a href="/images/gallery/ptok/pt11.jpg">
                        <img src="/images/gallery/ptok/pt11.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/ptok/pt12.jpg">
                        <img src="/images/gallery/ptok/pt12.jpg" style={{ display: 'none' }} alt="" />
                        </a>      
                  </div>
               </div>
        
               <div class="col-md-4 ">
                <div class="content lightgallery" style={{ backgroundImage: 'url("/images/gallery/viok/vi01.jpg")' }}>
                    <a href="/images/gallery/viok/vi01.jpg" >
                        <div class="content-overlay"></div>
                      <img class="content-image" src="/images/gallery/viok/vi01.jpg" style={{ display: 'none' }} />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">Nome do album</h3>
                        <p class="content-text">Pequena descrição</p>
                      </div>
                    </a>
                    
                      <a href="/images/gallery/viok/vi02.jpg">
                        <img src="/images/gallery/viok/vi02.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/viok/vi03.jpg">
                        <img src="/images/gallery/viok/vi03.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/viok/vi04.jpg">
                        <img src="/images/gallery/viok/vi04.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/viok/vi05.jpg">
                        <img src="/images/gallery/viok/vi05.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                        <a href="/images/gallery/viok/vi12.jpg">
                        <img src="/images/gallery/viok/vi12.jpg" style={{ display: 'none' }} alt="" />
                        </a>
                           
                  </div>
               </div>
            </div>
         </div>
      </section>
        );
    }
}

export default Gallery;