import React, { Component } from 'react';



class Header extends Component {
    render() {
        return (
            <section class="menu cid-rSRoQBmD0Y" once="menu" id="menu2-5">
            <nav class="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
               <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <div class="hamburger">
                     <span></span>
                     <span></span>
                     <span></span>
                     <span></span>
                  </div>
               </button>
               <div class="menu-logo">
                  <div class="navbar-brand">
                     <span class="navbar-logo">
                     <a href="">
                     <img  src="/images/logo.png" alt="Vera Cruz Tours" />
                     </a>
                     </span>
                  </div>
               </div>
               <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                     <li class="nav-item">
                        <a class="nav-link link text-black display-4" href="#header2-6">
                        Home
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link link text-black display-4" href="#header16-a">
                        Quem somos
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link link text-black display-4" href="#content4-e">
                        Serviços
                        </a>
                     </li>
                     <li class="nav-item">
                       <a class="nav-link link text-black display-4" href="#galeria">
                       Galeria
                       </a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link link text-black display-4" href="#clients-g">
                        Parceiros
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link link text-black display-4" href="#form1-h">
                        Contatos
                        </a>
                     </li>
                  </ul>
                  <div class="navbar-buttons mbr-section-btn">
                     <a class="btn btn-sm btn-primary display-4" href="tel:+351 926 009 805">
                     <span class="btn-icon mbri-mobile mbr-iconfont mbr-iconfont-btn">
                     </span>
                     +351 926 009 805
                     </a>
                  </div>
               </div>
            </nav>
         </section>
          );
    }
 
}

export default Header;