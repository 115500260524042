import React, { Component } from 'react';
import axios from 'axios';

class Contatos extends Component {
   constructor(props) {
      super(props);
      this.state = {
        name: '',
        phone: '',
        email: '',
        message: '',
        mailSent: false,
        error: null
      }
    }

    handleFormSubmit = e => {
      e.preventDefault();
      axios({
        method: 'POST',
        url: 'https://veracruz.marrs.com.br/controllers/formController.php',
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent,
          })
        })
        .catch(error => this.setState({ error: error.message }));
        
        document.getElementById('name').value = "";
        document.getElementById('email').value = "";
        document.getElementById('phone').value = "";
        document.getElementById('message').value = "";

    };



   
    render() {
        return (
         
            <section class="mbr-section form1 cid-rSRt9Gt7TR" id="form1-h">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="title col-12 col-lg-8">
                     <h2 class="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                        Contato
                     </h2>
                     <h3 class="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">
                     Ficou interessado(a)? Entre em contato e ficaremos felizes em atendê-lo.
                     </h3>
                  </div>
               </div>
            </div>
            <div class="container">
               <div class="row justify-content-center">
                  <div class="media-container-column col-lg-8" >
               
                     <form action="" id='mailform' method="POST" class="mbr-form form-with-styler" data-form-title="Mobirise Form">
                        <input type="hidden" name="email" data-form-email="true" value="Ghb2+gIOsP2Vg5EqAPl7Yylq5e60BpYm6N1v8i9zGWPZIKjxV1CkdGXAdrIPJb+fI5zfEwva4FR7LNVz6idOYaC5b9Tms+PZV20NXLsSrJRtlo9fjJmUmcpoFCvx0ZkR" />
                        <div>
                             {this.state.mailSent &&
                                <div class="alert alert-success col-12"> E-mail enviado. Agradecido por seu e-mail!</div>
                             }
                        </div>
                        <div class="row">
                           <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Agradecido por seu e-mail!</div>
                           <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12"></div>
                        </div>
                        <div class="dragArea row">
                           <div class="col-md-4  form-group" data-for="name">
                              <label for="name" class="form-control-label mbr-fonts-style display-7">Nome completo</label>
                              <input type="text" name="name" data-form-field="Name" required="required" class="form-control display-7" id="name"  onChange={e => this.setState({ name: e.target.value })}/>
                           </div>
                           <div class="col-md-4  form-group" data-for="email">
                              <label for="email" class="form-control-label mbr-fonts-style display-7">Email</label>
                              <input type="email" name="email" data-form-field="Email" required="required" class="form-control display-7" id="email"  onChange={e => this.setState({ email: e.target.value })}/>
                           </div>
                           <div data-for="phone" class="col-md-4  form-group">
                              <label for="phone" class="form-control-label mbr-fonts-style display-7">Telefone</label>
                              <input type="tel" name="phone" data-form-field="Phone" class="form-control display-7" id="phone"  onChange={e => this.setState({ phone: e.target.value })}/>
                           </div>
                           <div data-for="message" class="col-md-12 form-group">
                              <label for="message" class="form-control-label mbr-fonts-style display-7">Escreva sua mensagem</label>
                              <textarea name="message" data-form-field="Message" class="form-control display-7" id="message"  onChange={e => this.setState({ message: e.target.value })}></textarea>
                           </div>
                           <div class="col-md-12 input-group-btn align-center"><button type="submit" onClick={e => this.handleFormSubmit(e)} class="btn btn-primary btn-form display-4">ENVIAR</button></div>
                        </div>

                     </form>
                    
                  </div>
               </div>
            </div>
        </section>
        );
    }
}

export default Contatos