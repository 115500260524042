import React, { Component } from 'react';




class Footer extends Component {
    render() {
        var facebook = "https://facebook.com";
        var instagram= "https://instagram.com";
        var twitter = "https://twiiter.com";
        var youtube = "https://youtube.com";

        return (
            <section class="cid-rSRttPcdmZ" id="footer5-i">
            <div class="container">
               <div class="media-container-row">
                  <div class="col-md-3">
                     <div class="media-wrap">
                        <a href="#">
                        <img src="/images/logo.png"  alt="Vera Cruz - Logo" />
                        </a>
                     </div>
                  </div>
                  <div class="col-md-9">
                     <p class="mbr-text align-right links mbr-fonts-style display-7">
                        <a href="#header2-6" class="text-black">HOME</a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#header16-a" class="text-black">QUEM SOMOS</a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#content4-e" class="text-black">SERVIÇOS</a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#galeria" class="text-black">GALERIA</a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#clients-g" class="text-black">PARCEIROS</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#form1-h" class="text-black">CONTATOS</a>
                     </p>
                  </div>
               </div>
               <div class="footer-lower">
                  <div class="media-container-row">
                     <div class="col-md-12">
                        <hr />
                     </div>
                  </div>
                  <div class="media-container-row mbr-white">
                     <div class="col-md-6 copyright">
                        <p class="mbr-text mbr-fonts-style display-7">
                           © <strong>Vera Cruz Tours</strong>. <script>document.write(new Date().getFullYear());</script> - Todos os direitos reservados. <br/> Desenvolvido por <a href="https://marrs.com.br" target="_blank"><strong>Marrs Studio</strong></a>
                        </p>
                     </div>
                     <div class="col-md-6">
                        <div class="social-list align-right">
                           <div class="soc-item">
                              <a href={twitter} target="_blank">
                              <span class="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                           <div class="soc-item">
                              <a href={ facebook } target="_blank">
                              <span class="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                           <div class="soc-item">
                              <a href={youtube} target="_blank">
                              <span class="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                           <div class="soc-item">
                              <a href={instagram} target="_blank">
                              <span class="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                           <div class="soc-item">
                              <a href="#" target="_blank">
                              <span class="socicon-googleplus socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                           <div class="soc-item">
                              <a href="#" target="_blank">
                              <span class="socicon-behance socicon mbr-iconfont mbr-iconfont-social"></span>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
          );
    }
 
}

export default Footer;